import React, { useState } from 'react';
import Layout from '../../components/layout';
import ServiceFooter from '../../components/ServiceFooter';
import { Col, Row } from 'react-bootstrap';
import { imagePathURL } from '../../utils/JSONData';
import { graphql } from 'gatsby';
import dotImg from '../../Assets/images/Home/dotImg.png';
import Pulse from 'react-reveal/Pulse';
import Fade from 'react-reveal/Fade';
import "../../Assets/styles/services.scss";
import { stacks } from '../../Assets/images/stack';
import Scrolltop from '../../components/Scrolltotop/scrolltop';
import Footer from '../../components/Footer/Footer';

const Node = ({ data }) => {

  const nodeServices = [
    {
      id: 0,
      title: 'Node Consultation',
      details: 'Our Node consultants provide comprehensive guidance, starting from the ground up, to meet your Node application development requirements.',
      image: "https://admin.madhavtechno.com/uploads/Consultation_a6fcb3e606.png"
    },
    {
      id: 1,
      title: 'Node Migration',
      details: 'Elevate your legacy applications by seamlessly transitioning to Node, harnessing its capabilities for mobile app development.',
      image: "https://admin.madhavtechno.com/uploads/Development_781bf6f65c.png"
    },
    {
      id: 2,
      title: 'Node Resource Augmentation',
      details: 'Leverage the expertise of dedicated Node developers to build agile and highly secure mobile applications.',
      image: "https://admin.madhavtechno.com/uploads/Development_1b899a311e.png"
    },
    {
      id: 3,
      title: 'Node App Development',
      details: 'Our seasoned Node developers enable you to create high-performing, cross-platform, native mobile apps with expertise.',
      image: "https://admin.madhavtechno.com/uploads/UI_Design_410d03b060.png"
    },
    {
      id: 4,
      title: 'Node UI Design',
      details: 'Craft interactive UI designs with feature-rich functionalities using our Node development skills.',
      image: "https://admin.madhavtechno.com/uploads/Resource_9f81998013.png"
    },
    {
      id: 5,
      title: 'Node Maintenance and Support',
      details: 'As a leading Node app development company, we offer ongoing error handling and maintenance services post-deployment.',
      image: "https://admin.madhavtechno.com/uploads/Maintenance_48c82ee210.png"
    }
  ]

  const nodetechnologyStack = [
    {
      id: 0,
      title: 'Backend',
      image: 'https://admin.madhavtechno.com/uploads/Backend_a781633728.png',
      dataArr: [
        { name: 'Express.js', image: stacks.Expressjs },
        { name: 'Nest.js', image: stacks.Nestjs },
        { name: 'Hapi.js', image: stacks.Hapijs },
        { name: 'LoopBack', image: stacks.LoopBack },
        { name: 'Sails.js', image: stacks.Sailsjs },
        { name: 'Puppeteer', image: stacks.Puppeteer },
        { name: 'Socket.IO', image: stacks.SocketIO },
        { name: 'Websocket', image: stacks.websocket },
        { name: 'GraphQL', image: stacks.GraphQL }
      ]
    },
    {
      id: 4,
      title: 'Database',
      image: 'https://admin.madhavtechno.com/uploads/Database_de87ff3bcc.png',
      dataArr: [
        { name: 'MongoDB', image: stacks.MongoDB },
        { name: 'PostgreSQL', image: stacks.PostgreSQL },
        { name: 'MySQL', image: stacks.MySQL },
        { name: 'SQLite', image: stacks.SQlite },
        { name: 'Redis', image: stacks.Redis },
        { name: 'Oracle', image: stacks.Oracle },
        { name: 'DynamoDB', image: stacks.DynamoDB }
      ]
    },
    {
      id: 3,
      title: 'Testing',
      image: 'https://admin.madhavtechno.com/uploads/Testing_7725967b2e.png',
      dataArr: [
        { name: 'Postman', image: stacks.Postman },
        { name: 'Mocha', image: stacks.Mocha },
        { name: 'Chai', image: stacks.Chai },
        { name: 'Jest', image: stacks.Jest},
        // { name: 'Supertest', image: 'url_to_supertest_image' },
        { name: 'Cypress', image: stacks.Cypress }
      ]
    },
    {
      id: 5,
      title: 'Cloud & DevOps',
      image: 'https://admin.madhavtechno.com/uploads/Clouddevops_d86784a818.png',
      dataArr: [
        { name: 'AWS', image: stacks.AWS },
        { name: 'Google Cloud', image: stacks.GoogleCloud },
        { name: 'Azure', image: stacks.Azure },
        { name: 'Docker', image: stacks.Docker },
        { name: 'Kubernetes', image: stacks.Kubernetes },
        { name: 'CI/CD', image: stacks.CICD },
        { name: 'Jenkins', image: stacks.Jenkins },
        { name: 'Heroku', image: stacks.Heroku }
      ]
    },
    {
      id: 1,
      title: 'Others',
      image: 'https://admin.madhavtechno.com/uploads/Others_2f8e35a9b8.png',
      // dataArr: ['Trello', 'RESTful API', 'BLE', 'Jira', 'Socket.IO', 'Slack', 'Redux', 'GraphQL', 'Third Party API/SDK Integration']
      dataArr: [
        { name: 'JavaScript / TypeScript', image: stacks.javascript },
        { name: 'RESTful API', image: stacks.restapi },
        { name: 'GraphQL', image: stacks.GraphQL },
        { name: 'Kafka', image: stacks.Kafka },
        { name: 'Sequelize ORM', image: stacks.Sequelize },
        { name: 'Mongoose ORM', image: stacks.MongoDB },
        { name: 'Prisma ORM', image: stacks.prisma },
        { name: 'RabbitMQ', image: stacks.rabbitmq },
        { name: 'JWT / Cryptojs', image: stacks.JWT }
      ]
    },
    // {
    //   id: 2,
    //   title: 'Front-end',
    //   image: 'https://admin.madhavtechno.com/uploads/Frontend_08a71e145a.png',
    //   dataArr: ['React Native Elements', 'Material-UI', 'NativeBase']
    // },
  ]

  const { allServices, allServiceDevelopmentServices, allTechnologyStacks, allGetInTouchs } = data

  const [selectedStack, setSelectedStack] = useState(0);

  const handleStackClick = (index) => {
    setSelectedStack(index);
  };
  return (
    <>
      <Layout>
        <div className="service_react_group">
          <Row>
            <Col sm={12} md={8} className="m-auto">
              <div>
                <div className="service_react_title">
                  {/* {allServices.nodes[0].data.attributes.title} */}
                  Node App Development Company India
                </div>
                <div className="service_react_subtitle">
                  {/* {allServices.nodes[0].data.attributes.description1} */}
                  Get ahead in the digital landscape with Node.js web app development.
                </div>
                <div className="service_react_subtitle2">
                  {/* {allServices.nodes[0].data.attributes.description2} */}
                  Revolutionize your application development process with Node.js, the dynamic platform known for its versatility and speed.
                  We excel in providing strategic guidance on Node.js development. Our experienced team ensures that you make the most of Node.js's capabilities,
                  allowing you to create cross-platform mobile and web applications that are not only highly functional but also scalable and irresistible to users
                </div>
                <div className="service_react_button">
                  <button className="service_freequote">GET A FREE QUOTE</button>
                  <button className="service_ourwork">OUR WORK</button>
                </div>
              </div>
            </Col>
            <Col sm={12} md={4}>
              <div>
                <Pulse>
                  {/* <img src={imagePathURL + allServices.nodes[0].data.attributes.image.data.attributes.url} alt="react-logo" /> */}
                  <img src={'https://www.svgrepo.com/show/378837/node.svg'} alt="react-logo" />
                </Pulse>
              </div>  
            </Col>
          </Row>
        </div>
        <div className="position-relative">
          <div className="service_react_title px-4 text-center">
            {/* {allServices.nodes[0].data.attributes.section1Title} */}
            Node Development Services
          </div>
          <div className="service_react_subtitle px-4 text-center">
            {/* {allServices.nodes[0].data.attributes.section1Desc} */}
            Redefine business success through leading-edge Node.js app development
          </div>
          <div className="service_cross-platform">Cross Platform</div>
          <img src={dotImg} alt="dot" className="service_dot-image" />
          {/* <div className="service_RN_develop">
          {allServiceDevelopmentServices.nodes[0].data.map((item, i) => {
            return (
              <Fade top>
                <div className="service_develop_group" key={i}>
                  <img className="service_developimg" src={imagePathURL + item?.attributes.image.data.attributes.url} alt="searvice" />
                  <div className="service_developtitle">{item.attributes.title}</div>
                  <div className="service_developdetails">{item.attributes?.description}</div>
                </div>
              </Fade>
            );
          })}
        </div> */}
          <div className="service_RN_develop">
            {nodeServices.map((item, i) => {
              return (
                <Fade top key={i}>
                  <div className="service_develop_group">
                  <div className="service_develop_content">
                    <img className="service_developimg" src={item.image} alt="service" />
                    <div className="service_developtitle">{item.title}</div>
                    <div className="service_developdetails">{item.details}</div>
                  </div>
                  </div>
                </Fade>
              );
            })}
          </div>
        </div>
        <div className="service_quote_group">
          <div className="service_title_group">
            <div className="service_quote_title">
              {allGetInTouchs.nodes[0].data.attributes.title}
            </div>
            <div className="service_quote_details">
              {allGetInTouchs.nodes[0].data.attributes.description}
            </div>
          </div>
          <div>
            <button className="service_quote_button">
              {allGetInTouchs.nodes[0].data.attributes.btnTxt}
            </button>
          </div>
        </div>
        <div className="service_trofi_content">
          <div className="service_react_title text-center mx-2">
            {allServices.nodes[0].data.attributes.section2Title}
          </div>
          <div
            className="service_react_subtitle text-center mx-2"
          >
            {allServices.nodes[0].data.attributes.section2Subtitle}
          </div>
          <div className="service_trofi_subcontent">
            <Row>
              <Col sm={12} md={6} className="m-auto">
                <div className="service_trofi_title_content">
                  <div className="service_trofi_title">
                    {allServices.nodes[0].data.attributes.section2ContentTitle}
                  </div>
                  <div className="service_developdetails">
                    {allServices.nodes[0].data.attributes.section2ContentSubTitle}
                  </div>
                  <div className="service_trofi_details">
                    {allServices.nodes[0].data.attributes.section2ContentDesc}
                  </div>
                  <Fade top>
                    <button className="service_viewCaseStudy"> {allServices.nodes[0].data.attributes.section2ContentBtnTxt}</button>
                  </Fade>
                </div>
              </Col>
              <Col sm={12} md={6} className="m-auto text-center">
                <div>
                  <Pulse>
                    <img
                      className="service_trofiDevice_img"
                      src={imagePathURL + allServices.nodes[0].data.attributes.section2ContentImage.data.attributes.url}
                      alt="trofi"
                    />
                  </Pulse>
                </div>
              </Col>
            </Row>
          </div>
          <div className="service_react_title text-center m-auto">
            <span style={{ display: "inline-block" }}>

              Technology Stack
              <div className='text-item' style={{ display: "flex", justifyContent: "center",padding:'5px 0 29px 0' }} >
                <div style={{ borderBottom: "3px solid #FF9C4F", borderRadius: "10px", marginTop: "10px", width: "25%", marginRight: "10px" }}></div>
                <div style={{ borderBottom: "3px solid #FF9C4F", borderRadius: "10px", marginTop: "10px", width: "3%" }}></div>
                <div style={{ borderBottom: "3px solid #FF9C4F", borderRadius: "10px", marginTop: "10px", width: "25%", marginLeft: "10px" }}> </div>
              </div>
            </span>
          </div>
          {/* <div>
          <div className="service_line1"></div>
        </div> */}
          {/* <div className="service_stack_content">
            {allTechnologyStacks.nodes[0].data.map((item, i) => {
              return (
                <Fade top>
                  <div className="service_stack_group" key={i}>
                    <div className="service_stack_title_content">
                      <img src={imagePathURL + item.attributes?.image.data.attributes.url} alt="technology" />
                      <div className="service_stack_title">{item.attributes.title}</div>
                    </div>
                    <div className="service_arr_group">
                      {item.attributes.technologyName?.replace(/\[|\]/g, '').split(',').map((d, i) => {
                        return (
                          <div
                            className="service_quote_details"
                            style={{
                              margin: "auto",
                              display: "flex",
                              alignItems: "center",
                              fontSize: '14px'
                            }}
                            key={i}
                          >
                            <div className="service_bullets"></div>
                            {d}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Fade>
              );
            })}
          </div> */}
          {/* <div className="service_stack_content">
            {nodetechnologyStack.map((item, i) => {
              return (
                <Fade top>
                  <div className="service_stack_group" key={i}>
                    <div className="service_stack_title_content">
                      <img src={item.image} alt="technology" />
                      <div className="service_stack_title">{item.title}</div>
                    </div>
                    <div className="service_arr_group">
                      {item.dataArr.map((technology, i) => (
                        <div className="service_quote_details" 
                        style={{
                          margin: "auto",
                          display: "flex",
                          alignItems: "center",
                          fontSize: '14px'
                        }} key={i} >
                          <div className="service_bullets"></div>
                          {technology}
                        </div>
                      ))}
                    </div>
                  </div>
                </Fade>
              );
            })}
          </div> */}
          <div className='technology'>
            <div className='technologytab'>
              {nodetechnologyStack.map((stack, index) => (
                <ul className={`tabbar-ul ${selectedStack === index ? 'active' : ''}`} key={index}>
                  <li
                    className='tabbar-li'
                    onClick={() => handleStackClick(index)}
                  >
                    {stack.title}
                  </li>
                </ul>
              ))}
            </div>
            <hr />
            <div className='technologydetalis'>
              {/* {selectedStack && ( */}
                <div className='stackdata'>
                  {nodetechnologyStack[selectedStack].dataArr.map((item, index) => (
                    <ul>
                    {/* <img src="https://admin.madhavtechno.com/uploads/Maintenance_48c82ee210.png" /> */}
                    <img src={item.image} alt={selectedStack.title} style={{ height: item.name === 'Websocket' ? 72 : '', width: item.name === 'Websocket' ? 80 :''}} />
                    <li key={index}>{item.name}</li>
                  </ul>
                  ))}
                </div>
              {/* )} */}
            </div>
          </div>
        </div>
        {/* <ServiceFooter /> */}
        <Footer />
        <Scrolltop />
      </Layout>
    </>
  )
}

export const query = graphql`
  {
    allServices {
      nodes {
        data {
          attributes {
            description1
            description2
            image {
              data {
                attributes {
                  url
                }
              }
            }
            section1Desc
            section1Title
            section2ContentBtnTxt
            section2ContentDesc
            section2ContentImage {
              data {
                attributes {
                  url
                }
              }
            }
            section2ContentSubTitle
            section2ContentTitle
            section2Subtitle
            section2Title
            title
          }
        }
      }
    }

    allServiceDevelopmentServices {
      nodes {
        data {
          attributes {
            description
            title
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }

    allTechnologyStacks {
      nodes {
        data {
          attributes {
            technologyName
            title
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }

    allGetInTouchs {
      nodes {
        data {
          attributes {
            title
            description
            btnTxt
          }
        }
      }
    }
  }
`;


export default Node